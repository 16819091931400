import React from 'react';
// import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import '../styles/global.scss';
// import Footer from './Footer';
import Nav from './Nav';

function Layout ({ children }) {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Zdeněk Hásek</title>
                <link rel="canonical" href="http://zdehasek.com" />
            </Helmet>
            <div className="columns">
                <div className="column is-full">
                    <Nav className="navigation" />
                </div>
            </div>
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div className="content">
                        { children }
                    </div>
                </div>
            </div>
            {/* <div className="columns">
                <div className="column is-full">
                    <Footer className="footer" />
                </div>
            </div> */}
        </>
    );
}

// Layout.propTypes = {
//     // eslint-disable-next-line react/forbid-prop-types
//     children: PropTypes.oneOfType([
//         PropTypes.array,
//         PropTypes.object
//     ]).isRequired
// };

export default Layout;
