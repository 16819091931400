// TODO: Styles are weird with a <button> so disabling for now.
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import SocialLinks from './SocialLinks';
import Icons from '../components/Icons';

function Nav () {
    const [isActive, setIsActive] = useState(false);

    const NavLink = (name, to) => (
        <Link
            className="navbar-item"
            to={to}
            onClick={() => { setIsActive(!isActive); }}
        >
            {name}
        </Link>
    );

    const SocLink = (type, size) => (
        <div
            className="navbar-item"
        >
            <SocialLinks type={type} size={size} />

        </div>
    );

    return (

        <nav className="navbar is-white" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className="navbar-brand">
                    <Link className="navbar-item" to="/">
                        {/* Zdeněk Hásek */}
                        {/* <Icons icon="faHome" size="2x" /> */}
                        Home
                    </Link>
                    {NavLink('Skills', '/skills')}
                    {/* {NavLink('Blog', '/blog')} */}

                </div>

                {/* <a
                    onClick={() => { setIsActive(!isActive); }}
                    role="button"
                    className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbar"
                    tabIndex={0}
                >
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                    <span aria-hidden="true" />
                </a> */}
            </div>
            <div
                id="navbar"
                className={`navbar-menu ${isActive ? 'is-active' : ''}`}
            >
                <div className="navbar-end">
                    {/* {NavLink('Blog', '/blog')} */}
                    {/* {NavLink('About', '/about')} */}
                    {/* {NavLink('Skills', '/skills')} */}
                    {/* {NavLink('Work', '/work')} */}
                    {/* {NavLink('Travel map', '/travel-map')} */}
                    {/* {SocLink('instagram', '1x')}
                    {SocLink('twitter', '1x')}
                    {SocLink('mail', '1x')} */}
                </div>
            </div>
        </nav>
    );
}

export default Nav;
