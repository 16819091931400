import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope, faHashtag, faPiggyBank, faHome
} from '@fortawesome/free-solid-svg-icons';
import {
    faGithub, faInstagram, faLinkedin, faTwitter
} from '@fortawesome/free-brands-svg-icons';

function Icons ({ icon, size }) {
    switch (icon) {
        case 'faGithub':
            return <FontAwesomeIcon icon={faGithub} size={size} />;
        case 'faInstagram':
            return <FontAwesomeIcon icon={faInstagram} size={size} />;
        case 'faLinkedin':
            return <FontAwesomeIcon icon={faLinkedin} size={size} />;
        case 'faTwitter':
            return <FontAwesomeIcon icon={faTwitter} size={size} />;
        case 'faEnvelope':
            return <FontAwesomeIcon icon={faEnvelope} size={size} />;
        case 'faHashtag':
            return <FontAwesomeIcon icon={faHashtag} size={size} />;
        case 'faPiggyBank':
            return <FontAwesomeIcon icon={faPiggyBank} size={size} />;
        case 'faHome':
            return <FontAwesomeIcon icon={faHome} size={size} />;
        default:
            return <div>NO IMAGE FOUND</div>;
    }

}

Icons.propTypes = {
    icon: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired
};

export default Icons;
